import { Fab, styled } from '@mui/material';
import { ContactSupport } from '@mui/icons-material';

const SupportButton = () => {
  return (
    <>
      <FloatingActionButtonArea>
        <Fab
          color="secondary"
          aria-label="Contact Support"
          variant="extended"
          href='mailto:YouthWorkOne@NYA.org.uk'
        >
          <ContactSupport /> Support
        </Fab>
      </FloatingActionButtonArea>
    </>
  );
};

const FloatingActionButtonArea = styled('div')(({ theme }) => ({
  zIndex: 1,
  position: 'fixed',
  right: theme.spacing(2),
  bottom: theme.spacing(2)
}));

export default SupportButton;
